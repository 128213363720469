<template>
  <div class="newZx">
    <div class="topp">
      <div>
        <div class="toppa">
          <p>排班日期</p>
          <el-date-picker

              class="inp"
              v-model="times"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>

          <p class="top-t">医生</p>
          <el-select class="inp" v-model="expertId" placeholder="请选择">
            <el-option
                v-for="item in expert"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

          <el-button style="margin-left: 20px;" type="primary" @click="tj">筛选</el-button>
        </div>

        <div v-if="0" class="toppa" style="padding-top: .2rem">
          <p>就诊类别</p>
          <el-select class="inp" v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

          <p class="top-t" style="opacity: 0">医生</p>
          <el-select class="inp" v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="user">
      <div>顾客：{{cs.CustomerName}}</div>
      <div>生日：{{cs.CustomerBirthday}}2</div>
      <div>电话：{{cs.CustomerTel}}</div>
    </div>

    <ul class="content back4">
      <li>医生</li>
      <li>预约日期</li>
      <li>预约时间</li>
      <li>类型</li>
      <li>挂号费</li>
      <li>诊室</li>
      <li>号源</li>
      <li></li>
    </ul>
    <div class="content-box">
      <ul class="content xzs" v-for="item in arr">
        <li>{{item.Expert.ExpertName}}</li>
        <li>{{item.ScheduleDate}}</li>
        <li>{{item.ks}}-{{item.js}}</li>
        <li>{{item.ScheduleType}}</li>
        <li>{{item.ScheduleCost}}</li>
        <li>{{item.Dept.DeptName}}</li>
        <li>{{item.ScheduleReservationCount}}/{{item.ScheduleReservations}}</li>
        <li><el-button type="primary" size="small" @click="addyy(item.ScheduleID, item.ks)">预约</el-button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {Loading, MessageBox} from "element-ui";

export default {
  name: "newZx",
  data() {
    return {
      value1: '',
      value: '',
      times: '',
      options: [
      ],
      expert: [],
      expertId: '',
      expertName: '',
      arr: [],
      cs: {}
    }
  },
  props: {
    ChooseId: {
      default: ''
    }
  },
  created() {
    if (this.$store.state.users.CsUser) {
      this.cs = this.$store.state.users.CsUser
    }
    if (!this.$store.state.users.employees) {
      this.$alert('员工未登录请登录！', '提示')
      .then(res => {
        this.$router.push({path: '/employeesLanding'})
      })
      return;
    }
    let date = new Date(), n='', y='', r=''
    n = date.getFullYear()
    y = date.getMonth() + 1
    r = date.getDate()
    this.times = `${n}-${y}-${r}`
    // console.log(this.times)

    let log = this.$loading({
      text: "加载中..."
    })

    this._api.userCurdApi.getEmployeesList()
    .then(res => {
      log.close()
      if (res.GetListResult && res.GetListResult.length > 0) {
        let arr = []
        for (let i = 0; i < res.GetListResult.length; i++) {
          arr.push({
            value: res.GetListResult[i].ExpertId,
            label: res.GetListResult[i].ExpertName
          })
        }
        this.expert = [...arr]
        this.expertName = this.expert[0].label
      }
    })

    this.getpb('', this.times)
  },
  methods: {
    getpb(id, time) {
      this._api.physicianVisits.getExpertScheduleList(id, time)
          .then(res => {
            if (res.GetListResult && res.GetListResult.length > 0) {
              let arr = []
              for (let i = 0; i < res.GetListResult.length; i++) {
                res.GetListResult[i].Dept = res.GetListResult[i].Dept?res.GetListResult[i].Dept:{
                  DeptName: ''
                }
                res.GetListResult[i].ks = this.clTime(res.GetListResult[i].ScheduleStartTime)
                res.GetListResult[i].js = this.clTime(res.GetListResult[i].ScheduleEndTime)
                arr.push(res.GetListResult[i])
              }
              this.arr = arr
            }else {
              this.arr = []
            }
          })
    },
    clTime(str) {
      if (!str && str == '') {return null}
      let time = str.toString()
      if (time.indexOf('.') != -1) {
        time = time.split('.')
        time = time[0] + ':30'
        return time
      }else {
        return time+':00'
      }
    },

    addyy(id, time) {
      if (!this.$store.state.users.CsUser) {
        return this.$alert('客户未登陆', '提示')
      }
      let exid = this.arr[0].value, dates = this.times+ ' ' +time
      if (this.expertId) exid = this.expertId
      this._api.physicianVisits.addYy(exid, id, dates, this.ChooseId)
      .then(res => {
        if (res.InsertResult) {
          this.$alert('预约成功！', '提示')
        }else {
          this.$alert(res.msg, '提示')
        }
      })
    },

    timeBh(e) {
      if (this.expertId) {
        this.getpb(this.expertId, e)
      }else {
        this.getpb(this.expert[0].value, e)
      }
    },

    exBh(e) {
      if (!this.times) {
        return this.$alert('请选择时间日期！', '提示')
      }
      this.getpb(e, this.times)
    },

    tj() {
      let id = this.expertId || this.expert[0].value
      this.getpb(id, this.times)
    }
  }
}
</script>

<style scoped lang="scss">
  .newZx {
    width: 100%;
    height: 55vh;
    overflow-y: auto;
  }
  .topp {
    display: flex;
    justify-content: flex-end;
    .toppa {
      display: flex;
      align-items: center;
      justify-content: start;
      .inp {
        width: 12vw;
        margin-left: 5px;
      }
      .top-t {
        padding-left: .6rem;
      }
    }
  }
  .user {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: .2rem 0;
  }
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #40a7f6;
    li {
      width: 16%;
      padding: .1rem 0;
    }
  }
  .back4 {background: #5a5959; color: #ffffff}
  .content-box {
    height: 35vh;
    overflow-y: auto;
  }
  .content-box::-webkit-scrollbar { width: 0 !important}
  .xzs:hover {background: #eae9e9; cursor: pointer;}
</style>
